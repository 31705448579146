import svgFile1 from "../data/DownloadSVG.svg"
import svgFile2 from "../data/map_ap_unsplit.svg"
import svgFile3 from "../data/map_jk_unsplit.svg"

export const fillSVG = async ({data,checkVal,unSplitJK,unSplitJKTA,splitJK}) => {
    let finalSVG;
    if(unSplitJK){
        finalSVG = svgFile2
    }
    else if(unSplitJKTA){
        finalSVG = svgFile3
    }
    else if(splitJK){
        finalSVG = svgFile1
    }
    if(finalSVG){
        return await fetch(finalSVG)
        .then(response => {
            return response.text();
        })
        .then(async (svgText) => {
            // Now svgText contains the SVG as a string
            const parser = new DOMParser();
            const svgDocument = parser.parseFromString(svgText, 'image/svg+xml');

            // Get the SVG element
            const svg = svgDocument.querySelector('svg');

            // Now you can loop through and modify the elements
            await modifySvgElements(svg,data,checkVal);
            return svg;
        })
        .catch(error => console.error('Error loading SVG:', error));
    }
}

function modifySvgElements(svg,data,checkVal) {
    const elements = svg.querySelectorAll('*');
    elements.forEach((el) => {
        let ID;
        if (el.tagName === 'path') {
            if(el.id!==""){
                if(data[el.id]) el.setAttribute('fill', `${data[el.id]?.color}`);
                else el.setAttribute('fill', "#fff");
            }
        }
        if(el.tagName === 'text'){
            let split = el.id.split("_")
            if(split[1]==="value"){
                const tspans = el.querySelectorAll('tspan');
                tspans.forEach((tspan, index) => {
                    tspan.textContent = `${data[split[0]]?.value ? data[split[0]]?.value : "NA"}`;  // Change text of each <tspan>
                });
                if(split[0]!=="11" && split[0]!=="17" && split[0]!=="16" && split[0]!=="15" && split[0]!=="14" && split[0]!=="13" && split[0]!=="31" && split[0]!=="32" && split[0]!=="35"){
                    el.setAttribute('fill', `${parseFloat(data[split[0]]?.value).toFixed(2) > checkVal ? "#fff" : 'rgba(26, 55, 95, 1)'}`);
                }
            }
            if(split[1]==="name"){
                if(split[0]!=="11" && split[0]!=="17" && split[0]!=="16" && split[0]!=="15" && split[0]!=="14" && split[0]!=="13" && split[0]!=="31" && split[0]!=="32" && split[0]!=="35"){
                    el.setAttribute('fill', `${parseFloat(data[split[0]]?.value).toFixed(2) > checkVal ? "#fff" : 'rgba(26, 55, 95, 1)'}`);
                }
            }
        }
    });
 }
 